import React from 'react'


import Button, { PRIMARY } from '@components/button'

import AlertDrawer from './alert-modal'
import { CloseModalIcon, Emoji, FullWidthButton, Message, Modal, Paragraph, Title } from './style'

import { func, string } from 'prop-types'

const CartExpired = ({ alertKey, setAlertTypeVisibility, setExpiredCart }) => {
  const requestClose = () => {
    setExpiredCart()
    setAlertTypeVisibility(alertKey, false)
  }

  return (
    <AlertDrawer open onRequestClose={requestClose}>
      <CloseModalIcon onClick={requestClose} src="/static/icons/close.svg" />
      <Modal>
        <Message>
          <Title>Cart Expired</Title>
          <Emoji>💤</Emoji>
          <Paragraph>Uh oh! Looks like your cart got tired of waiting so we emptied it for you.</Paragraph>
        </Message>
        <FullWidthButton>
          <Button buttonType={PRIMARY} onClick={requestClose}>
            Keep Shopping
          </Button>
        </FullWidthButton>
      </Modal>
    </AlertDrawer>
  )
}

export default CartExpired

CartExpired.propTypes = {
  alertKey: string,
  setAlertTypeVisibility: func,
  setExpiredCart: func
}
