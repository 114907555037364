import React, { PureComponent } from 'react'

import { css } from '@emotion/react'
import styled from '@emotion/styled'
import Link from 'next/link'

import { TEST_IDS } from 'components/modal/test/constants'
import ROUTES from 'helpers/routes'
import Colors from 'microcomponents/colors'
import { SectionTitle } from 'microcomponents/typography'

import AlertDrawer from './alert-modal'
import { CloseModalIcon, Paragraph } from './style'

import { func, string } from 'prop-types'

export default class LocationOutOfService extends PureComponent {
  static propTypes = {
    alertKey: string,
    clearPotentialAddress: func,
    resetCurrentAction: func,
    setAlertTypeVisibility: func,
    toggleAddress: func
  }

  handleClosing = () => {
    const { alertKey, clearPotentialAddress, setAlertTypeVisibility, resetCurrentAction } = this.props

    setAlertTypeVisibility(alertKey, false)
    clearPotentialAddress()
    resetCurrentAction()
  }

  handleChangingAddress = () => {
    const { alertKey, setAlertTypeVisibility, toggleAddress, resetCurrentAction } = this.props

    setAlertTypeVisibility(alertKey, false)
    toggleAddress()
    resetCurrentAction()
  }

  render() {
    return (
      <AlertDrawer open onRequestClose={this.handleClosing} modalElementClass={DrawerStyling}>
        <CloseModalIcon onClick={this.handleClosing} src="/static/icons/close.svg" />
        <div data-e2eid={TEST_IDS.NO_SERVICE_AREA} style={{ padding: '2rem', textAlign: 'center' }}>
          <picture>
            <source srcSet="/static/location.png" type="image/png" />
            <img style={{ marginLeft: '0.33rem' }} src="/static/location.png" />
          </picture>
          <SectionTitle componentStyle={{ marginBottom: '2rem' }}>Area Not In Service</SectionTitle>
          {
            <Paragraph style={{ marginBottom: '2rem', lineHeight: '1.8rem' }}>
              Eaze is not currently available in your area. You can{' '}
              <a onClick={this.handleChangingAddress}>try a different address</a> or{' '}
              <Link href={ROUTES.DELIVERYAREA}>
                <a>view our current service area</a>
              </Link>
            </Paragraph>
          }
          <ButtonWrap>
            <Link href={ROUTES.DELIVERYAREA}>
              <a>
                <Button data-e2eid={TEST_IDS.VIEW_AREA} className="white">
                  View Service Area
                </Button>
              </a>
            </Link>
            <a>
              <Button data-e2eid={TEST_IDS.CHANGE_ADDRESS} onClick={this.handleChangingAddress}>
                Change My Address
              </Button>
            </a>
          </ButtonWrap>
        </div>
      </AlertDrawer>
    )
  }
}

const DrawerStyling = css`
  outline: none;
  background: white;
  font-size: 1.6rem;
  max-width: 50rem;

  @media (max-width: 991px) and (min-width: 560px) {
    width: 42rem;
    margin: 4rem auto 0;
  }

  @media (max-width: 559px) {
    border-top-left-radius: 0.4rem;
    border-top-right-radius: 0.4rem;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    width: 100%;
    max-width: 100%;
    margin-top: 6rem;
    height: 100%;
    margin-bottom: 0;
    padding-top: 6rem;
  }
`

const ButtonWrap = styled.div`
  display: flex;
  justify-content: center;

  a {
    width: 50%;

    &:first-of-type {
      margin-right: 1rem;
    }
  }
`
/*
  TODO move this into components/button
  However, we currently have a pill-style button that is used on /groups. We need to see if design is ok with dumping this in favor of this new pill-style button
*/
const Button = styled.button`
  border: 0px;
  cursor: pointer;
  outline: none;
  border-radius: 100rem;
  padding: 1.2rem 1.5rem;
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${Colors.primary[1]};
  border: 1px solid ${Colors.primary[1]};
  color: #fff;
  width: 100%;

  &.white {
    background-color: #fff;
    color: ${Colors.primary[1]};
  }
`
