import React from 'react'

import window from 'global/window'

import Button, { PRIMARY } from '@components/button'

import AlertDrawer from './alert-modal'
import { Emoji, FullWidthButton, Message, Modal, Paragraph, Title } from './style'

import { string } from 'prop-types'

const AppOutdated = ({ alertKey }) => {
  return (
    <AlertDrawer open allowClose={false} onRequestClose={() => {}}>
      <Modal>
        <Message>
          <Title>Eaze has been updated!</Title>
          <Emoji>⏰</Emoji>
          <Paragraph>A new version of Eaze is available.</Paragraph>
        </Message>
        <FullWidthButton>
          <Button buttonType={PRIMARY} onClick={() => window.location.reload()}>
            Update Now
          </Button>
        </FullWidthButton>
      </Modal>
    </AlertDrawer>
  )
}

AppOutdated.propTypes = {
  alertKey: string
}

export default AppOutdated
