import React, { PureComponent } from 'react'

import styled from '@emotion/styled'

import Button, { PRIMARY } from '@components/button'
import { defaultBorderRadius, fontSizeParagraph, white } from 'components/style-helpers/global-variables'
import AnnouncementImage from 'microcomponents/images/announcement-image'

import { TEST_IDS } from './test/constants.js'

import { func, object } from 'prop-types'


import AlertDrawer from '../alert-modal'
import { Message } from '../style'



export default class Announcement extends PureComponent {
  static propTypes = {
    newestAnnouncement: object,
    setDismissedAnnouncements: func
  }

  handleCloseRequest = () => {
    const { newestAnnouncement, setDismissedAnnouncements } = this.props
    setDismissedAnnouncements(newestAnnouncement.id)
  }

  render() {
    const { newestAnnouncement } = this.props

    return (
      <AlertDrawer open onRequestClose={this.handleCloseRequest}>
        <AnnouncementModal data-e2eid={TEST_IDS.COMPONENT}>
          <StyledAnnouncementImage imageUrl={newestAnnouncement.photoUrl} />
          <Content>
            <Message style={{ margin: '0' }}>
              <AnnouncementTitle>{newestAnnouncement.name}</AnnouncementTitle>
              <AnnouncementParagraph>{newestAnnouncement.description}</AnnouncementParagraph>
            </Message>
            <Button buttonType={PRIMARY} onClick={this.handleCloseRequest} data-e2eid={TEST_IDS.DISMISS_BUTTON}>
              Continue Browsing
            </Button>
          </Content>
        </AnnouncementModal>
      </AlertDrawer>
    )
  }
}

const AnnouncementModal = styled.div`
  border-radius: ${defaultBorderRadius};
  position: relative;
  overflow: hidden;
  outline: none;
  background: ${white};
  font-size: ${fontSizeParagraph};
  min-height: 10rem;
  max-height: 95%;
  width: 23rem;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  z-index: 11;
  padding-top: 0;
`

const Content = styled.div`
  padding: 1rem;
`

const StyledAnnouncementImage = styled(AnnouncementImage)`
  height: 23rem;
`

const AnnouncementTitle = styled.div`
  font-size: 2rem;
  font-weight: 200;
  letter-spacing: 0.06em;
  margin-top: 1rem;
`

const AnnouncementParagraph = styled.p`
  font-size: 1.22rem;
  font-weight: 200;
  letter-spacing: 0.03em;
  margin: 1rem 0;
`
