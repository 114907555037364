import React, { PureComponent } from 'react'

import window from 'global/window'

import Button, { PRIMARY } from '@components/button'

import AlertDrawer from './alert-modal'
import { Emoji, FullWidthButton, Message, Modal, Paragraph, Title } from './style'

import { func, string } from 'prop-types'

export default class CartSizeMismatch extends PureComponent {
  static propTypes = {
    alertKey: string,
    resetCartSizeMismatch: func,
    setAlertTypeVisibility: func
  }

  handleCloseRequest = () => {
    const { alertKey, resetCartSizeMismatch, setAlertTypeVisibility } = this.props
    resetCartSizeMismatch()
    setAlertTypeVisibility(alertKey, false)
    // refresh page to make sure that unavailable items are cleared from menu
    window.location.reload()
  }

  render() {
    return (
      <AlertDrawer open onRequestClose={this.handleCloseRequest}>
        <Modal>
          <Message>
            <Title>Unavailable items removed from cart</Title>
            <Emoji>😓</Emoji>
            <Paragraph>
              Sorry! We had to remove one or more items from your cart because they are no longer available.
            </Paragraph>
          </Message>
          <FullWidthButton>
            <Button buttonType={PRIMARY} onClick={this.handleCloseRequest}>
              Keep Shopping
            </Button>
          </FullWidthButton>
        </Modal>
      </AlertDrawer>
    )
  }
}
