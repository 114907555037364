/**
 * Component shows a loading placeholder image until the actual announcement image is loaded over
 */

import React from 'react'


import styled from '@emotion/styled'
import Image from 'next/image'

import { isMobile } from '@helpers/constants'
import { backgroundColorTooltip } from 'components/style-helpers/global-variables'

import { string } from 'prop-types'


export default class AnnouncementImage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      error: false,
      img: null,
      loaded: false
    }

    this.onLoad = this.onLoad.bind(this)
    this.onError = this.onError.bind(this)
  }

  onLoad(e) {
    this.setState({ loaded: true })
  }

  onError(e) {
    this.setState({ loaded: false, error: true })
  }

  componentDidMount() {
    const img = document.createElement('img')
    this.setState({ img })

    img.addEventListener('load', this.onLoad)
    img.addEventListener('error', this.onError)
    img.src = this.props.imageUrl
  }

  componentWillUnmount() {
    const img = this.state.img
    if (img) {
      img.removeEventListener('load', this.onLoad)
      img.removeEventListener('error', this.onError)
    }
  }

  render() {
    const { error } = this.state

    if (error) {
      return <AnnouncementLoading />
    }

    let src = this.props.imageUrl

    // we do this to enforce images are always preset=thumb for mobile
    if (isMobile) {
      src = `${this.props.imageUrl}?preset=thumb`
    }

    return (
      <AnnouncementImageContainer>
        <Image
          src={src}
          alt=""
          layout={'fill'}
          className={this.props.className}
        />
        <AnnouncementLoading />
      </AnnouncementImageContainer>
    )
  }
}

AnnouncementImage.propTypes = {
  className: string,
  imageUrl: string.isRequired
  // maxWidth: bool
}

const AnnouncementLoading = styled.div`
  width: 100%;
  height: 100%;
  background: ${backgroundColorTooltip};
`

const AnnouncementImageContainer = styled.div`
  overflow: hidden;
`
