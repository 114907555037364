/**
 * Non existent account modal component
 * Informs user the email or phone number they tried is not tied to an account
 * and gives the option to sign up or try a different identifier
 */
import { PureComponent } from 'react'

import Button, { PRIMARY } from '@components/button'
import ROUTES from 'helpers/routes'

import AlertDrawer from './alert-modal'
import { ButtonRow, Message, Modal, Paragraph, Title } from './style'

import { func, object, string } from 'prop-types'

export default class NonExistentAccount extends PureComponent {
  static propTypes = {
    alertKey: string,
    setAlertTypeVisibility: func,
    router: object
  }

  handleRequestUseDifferentIdentifier = () => {
    const { alertKey, setAlertTypeVisibility } = this.props
    setAlertTypeVisibility(alertKey, false)
  }

  handleRequestNewAccount = () => {
    const { alertKey, setAlertTypeVisibility, router } = this.props
    setAlertTypeVisibility(alertKey, false)
    router.push(ROUTES.SIGNUP)
  }

  render() {
    return (
      <AlertDrawer open allowClose={false} onRequestClose={this.handleRequestUseDifferentIdentifier}>
        <Modal>
          <Message>
            <Title>Oops!</Title>
            <Paragraph>{`That email address doesn't match our records. Try again or sign up for a new account.`}</Paragraph>
          </Message>
          <ButtonRow>
            <Button buttonType={PRIMARY} onClick={this.handleRequestNewAccount}>
              Create a new account
            </Button>

            <Button buttonType={PRIMARY} onClick={this.handleRequestUseDifferentIdentifier} inverted>
              Try a different email
            </Button>
          </ButtonRow>
        </Modal>
      </AlertDrawer>
    )
  }
}
