import Button, { PRIMARY } from '@components/button'
import ROUTES from 'helpers/routes'

import AlertDrawer from './alert-modal'
import { CloseModalIcon, Emoji, Message, Modal, Paragraph, Title } from './style'

import { func, object, string } from 'prop-types'

const StoreClosed = ({ closedMessage, alertKey, resetCurrentAction, currentAction, router }) => {
  const onClick = () => {
    currentAction ? resetCurrentAction() : router.push(ROUTES.MENU)
  }

  return (
    <AlertDrawer open onRequestClose={onClick}>
      <CloseModalIcon onClick={onClick} src="/static/icons/close.svg" />
      <Modal>
        <Message>
          <Title>{`We're currently closed.`}</Title>
          <Emoji>😴</Emoji>
          <Paragraph>{closedMessage}</Paragraph>
        </Message>
        <Button buttonType={PRIMARY} onClick={onClick}>
          Continue browsing
        </Button>
      </Modal>
    </AlertDrawer>
  )
}

export default StoreClosed

StoreClosed.propTypes = {
  alertKey: string,
  closedMessage: string,
  currentAction: string,
  resetCurrentAction: func,
  router: object
}
