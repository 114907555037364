/* TODO(Nick): Move all other modal styles into this file */
import styled from '@emotion/styled'

import {
  colorEtaMelon,
  colorLinkLight,
  colorTextDark,
  defaultBorderRadius,
  eazeBlue,
  fontSizeLabel,
  fontSizeParagraph,
  paddingContainer,
  white
} from 'components/style-helpers/global-variables'

export const Modal = styled.div`
  border-radius: ${defaultBorderRadius};
  position: relative;
  border: 1px solid ${colorLinkLight};
  overflow: auto;
  outline: none;
  padding: 1rem;
  background: ${white};
  font-size: ${fontSizeParagraph};
  min-height: 10rem;
  max-height: 95%;
  width: 23rem;
  z-index: 11;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & .noTopPadding {
    padding-top: 0;
  }

  & .noPadding {
    padding: 0;
  }
`

export const Message = styled.div`
  text-align: center;
  width: 100%;
  margin: 0 ${paddingContainer} 2.5rem;
`

export const ButtonWrapper = styled.div`
  margin-top: ${paddingContainer};
  width: 100%;
`

export const Comment = styled.div`
  width: 100%;
`

export const Title = styled.h2`
  font-family: 'Open Sans', 'Proxima', Helvetica;
  font-size: 2rem;
  font-weight: 800;
  letter-spacing: 0.03em;
  line-height: 1.8rem;
`

export const Paragraph = styled.p`
  font-size: 1.2rem;
  font-weight: 200;
  letter-spacing: 0.03em;
  margin: 0;
`

// yes this is ugly, but one of the modals used the paragraph class on a div
export const ParagraphDiv = styled.div`
  font-size: 1.2rem;
  font-weight: 200;
  letter-spacing: 0.03em;
  margin: 0;
`

export const MelonTextDiv = styled.div`
  color: ${colorEtaMelon};
  font-weight: 500;
`

export const MelonTextSpan = styled.span`
  color: ${colorEtaMelon};
  font-weight: 500;
`

export const ButtonRow = styled.div`
  justify-content: space-around;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;

  & button {
    margin-bottom: 1rem;
  }
`

export const FullWidthButton = styled.div`
  width: 100%;
`

export const Emoji = styled.div`
  font-size: 8rem;
`

export const CloseModalIcon = styled.img`
  width: 25px;
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  cursor: pointer;
  z-index: 5;
`

export const Logout = styled.div`
  padding: 0.5rem;
`

export const LogoutLink = styled.a`
  color: ${colorTextDark};
  font-size: ${fontSizeLabel};
`

export const CollectiveAgreementLink = styled.a`
  display: inline;
  &:link,
  &:visited,
  &:active {
    color: ${eazeBlue};
    text-decoration: underline;
  }
`
