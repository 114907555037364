import React from 'react'


import Button, { PRIMARY } from '@components/button'
import ROUTES from 'helpers/routes'

import AlertDrawer from './alert-modal'
import { ButtonRow, CloseModalIcon, Emoji, Message, Modal, Paragraph, Title } from './style'

import { func, object, string } from 'prop-types'

const UnderMinimum = ({ alertKey, setAlertTypeVisibility, cartMinimum, router }) => {
  const onRequestClose = () => {
    setAlertTypeVisibility(alertKey, false)
  }
  const onClick = () => {
    router.push(ROUTES.MENU)
  }

  return (
    <AlertDrawer open onRequestClose={onRequestClose}>
      <CloseModalIcon onClick={onRequestClose} src="/static/icons/close.svg" />
      <Modal>
        <Message>
          <Title>Almost there!</Title>
          <Emoji>🙌</Emoji>
          <Paragraph>
            Eaze has a ${cartMinimum} minimum to place an order. Add items to your cart to meet the minimum.
          </Paragraph>
        </Message>
        <ButtonRow>
          <Button buttonType={PRIMARY} onClick={onClick}>
            Browse full Eaze Menu
          </Button>

          <Button buttonType={PRIMARY} onClick={onRequestClose} inverted>
            View Cart
          </Button>
        </ButtonRow>
      </Modal>
    </AlertDrawer>
  )
}

export default UnderMinimum

UnderMinimum.propTypes = {
  alertKey: string,
  setAlertTypeVisibility: func,
  cartMinimum: string,
  router: object
}
