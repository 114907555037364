/**
 * Existing account modal component
 * Informs user an account already exists for the identifier submitted
 */

import React, { PureComponent } from 'react'

import Button, { PRIMARY } from '@components/button'
import { EMAIL } from '@helpers/constants'
import { track } from 'analytics'
import ROUTES from 'helpers/routes'

import AlertDrawer from './alert-modal'
import { ButtonRow, Emoji, Message, Modal, Paragraph, Title } from './style'
import { TEST_IDS } from './test/constants'

import { func, object, string } from 'prop-types'

export default class ExistingAccountIdentifier extends PureComponent {
  static propTypes = {
    alertKey: string,
    identifierType: string,
    logout: func,
    setAlertTypeVisibility: func,
    router: object
  }

  handleRedirectToLogin = () => {
    const { alertKey, identifierType, logout, router, setAlertTypeVisibility } = this.props
    if (identifierType === EMAIL) {
      track('Signup.DuplicateEmail.Login')
    } else {
      track('Signup.DuplicatePhone.Login')
    }
    setAlertTypeVisibility(alertKey, false)
    // User in verification flow will be logged into the account they're creating and should be logged out
    if (logout) {
      logout(ROUTES.LOGIN)
    } else {
      router.push(ROUTES.LOGIN)
    }
  }

  handleRedirectToResetPassword = () => {
    const { alertKey, identifierType, router, setAlertTypeVisibility } = this.props
    if (identifierType === EMAIL) {
      track('Signup.DuplicateEmail.ResetPassword')
    } else {
      track('Signup.DuplicatePhone.ResetPassword')
    }
    setAlertTypeVisibility(alertKey, false)
    router.push(ROUTES.RESET_PASSWORD)
  }

  componentDidMount() {
    if (this.props.identifierType === EMAIL) {
      track('Signup.DuplicateEmail.View')
    } else {
      track('Signup.DuplicatePhone.View')
    }
  }

  handleCloseRequest = () => {
    const { alertKey, setAlertTypeVisibility } = this.props
    setAlertTypeVisibility(alertKey, false)
  }

  render() {
    const { identifierType } = this.props
    const identifierTypeDisplay = identifierType === EMAIL ? 'email address' : 'phone number'

    return (
      <AlertDrawer open onRequestClose={this.handleCloseRequest}>
        <Modal data-e2eid={TEST_IDS.DUPLICATE_ACCOUNT_MODAL}>
          <Message>
            <Title>Good to see you again!</Title>
            <Emoji>👋</Emoji>
            <Paragraph>
              Your {identifierTypeDisplay} has already been used to create an account. Would you like to log in or reset
              your password?
            </Paragraph>
          </Message>

          <ButtonRow>
            <Button onClick={this.handleRedirectToResetPassword} buttonType={PRIMARY}>
              Reset my password
            </Button>

            <Button onClick={this.handleRedirectToLogin} buttonType={PRIMARY} inverted>
              Log in
            </Button>
          </ButtonRow>
        </Modal>
      </AlertDrawer>
    )
  }
}
