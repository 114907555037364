import { PureComponent } from 'react'

import Button, { PRIMARY } from '@components/button'
import AlertDrawer from 'microcomponents/drawer'

import { CollectiveAgreementLink, Emoji, Message, Modal, Paragraph, Title } from './style'

import { func, string } from 'prop-types'

export default class CollectiveAgreement extends PureComponent {
  static propTypes = {
    acceptCollectiveAgreement: func,
    alertKey: string,
    setAlertTypeVisibility: func
  }

  handleCloseRequest = (photo) => {
    const { alertKey, setAlertTypeVisibility, acceptCollectiveAgreement } = this.props
    setAlertTypeVisibility(alertKey, false)
    acceptCollectiveAgreement()
  }

  render() {
    return (
      <AlertDrawer open allowClose={false} onRequestClose={this.handleCloseRequest}>
        <Modal>
          <Message>
            <Title>{`We've updated our agreements!`}</Title>
            <Emoji>✍️</Emoji>
            <Paragraph style={{ marginBottom: '1rem' }}>
              In order to continue using Eaze, please review our updated terms.
            </Paragraph>
            <Paragraph>
              {`By clicking "I Agree," I accept Eaze's `}
              <CollectiveAgreementLink href="/terms-of-service" rel="noopener noreferrer" target="_blank">
                Terms of Service
              </CollectiveAgreementLink>{' '}
              and{' '}
              <CollectiveAgreementLink href="/privacy-policy" rel="noopener noreferrer" target="_blank">
                Privacy Policy
              </CollectiveAgreementLink>
              .
            </Paragraph>
          </Message>
          <Button buttonType={PRIMARY} onClick={this.handleCloseRequest}>
            I agree
          </Button>
        </Modal>
      </AlertDrawer>
    )
  }
}
