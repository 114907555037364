import Button, { PRIMARY } from '@components/button'
import ROUTES from 'helpers/routes'

import AlertDrawer from './alert-modal'
import { Emoji, Message, Modal, Paragraph, Title } from './style'

import { func, object, string } from 'prop-types'

const UnderAgeMinimum = ({ alertKey, setAlertTypeVisibility, router }) => {
  const requestClose = () => {
    router.push(ROUTES.MENU)
  }

  return (
    <AlertDrawer open allowClose={false} onRequestClose={() => {}}>
      <Modal>
        <Message>
          <Title>{`We're sorry!`}</Title>
          <Emoji>👶</Emoji>
          <Paragraph>You must be 21 or older to receive a delivery at your current location.</Paragraph>
        </Message>

        <Button onClick={requestClose} buttonType={PRIMARY}>
          Return to menu
        </Button>
      </Modal>
    </AlertDrawer>
  )
}

export default UnderAgeMinimum

UnderAgeMinimum.propTypes = {
  alertKey: string,
  router: object,
  setAlertTypeVisibility: func
}
