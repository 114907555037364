import Button, { PRIMARY } from '@components/button'

import AlertDrawer from './alert-modal'
import { CloseModalIcon, Emoji, FullWidthButton, Message, Modal, Paragraph, Title } from './style'

export default function RemovedPromo({ toggleRemovedPromo = () => null }: { toggleRemovedPromo: () => void }) {
  return (
    <AlertDrawer open onRequestClose={toggleRemovedPromo}>
      <CloseModalIcon onClick={toggleRemovedPromo} src="/static/icons/close.svg" alt="Close Popup" />
      <Modal>
        <Message>
          <Title>{`Your promo won't work anymore`}</Title>
          <Emoji>😢</Emoji>
          <Paragraph>
            {`Looks like your cart doesn't meet the promo criteria. We had to remove the promo from your cart.`}
          </Paragraph>
        </Message>
        <FullWidthButton>
          <Button buttonType={PRIMARY} onClick={toggleRemovedPromo}>
            OK
          </Button>
        </FullWidthButton>
      </Modal>
    </AlertDrawer>
  )
}
