import { PureComponent } from 'react'

import styled from '@emotion/styled'

import Button, { PRIMARY } from '@components/button'
import { track } from 'analytics'
import { defaultBorderRadius, white } from 'components/style-helpers/global-variables.js'
import Colors from 'microcomponents/colors'
import Logo from 'microcomponents/icons/logo'
import { SectionTitle, Text } from 'microcomponents/typography'

import AlertDrawer from './alert-modal'
import { TEST_IDS } from './test/constants.js'

import { func } from 'prop-types'

export default class AgeGate extends PureComponent {
  static propTypes = {
    setCookie: func
  }

  state = {
    open: false
  }

  handleConfirm = () => {
    const { setCookie } = this.props
    setCookie({ hasVerifiedAge: true })
    track('Modal.AgeGate.Confirm')
  }

  handleDismiss = () => {
    track('Modal.AgeGate.Dismiss')
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ open: true }, () => track('Modal.AgeGate.View'))
    }, 2000)
  }

  render() {
    return (
      <AlertDrawer allowClose={false} onRequestClose={() => {}} open={this.state.open}>
        <AgeGateModal data-e2eid={TEST_IDS.COMPONENT}>
          <Logo width={13} />
          <SectionTitleStyled>Discover the best in cannabis.</SectionTitleStyled>
          <TextStyled>You must be at least 21 years of age to view this site.</TextStyled>
          <Button buttonType={PRIMARY} onClick={this.handleConfirm} data-e2eid={TEST_IDS.VERIFICATION_BUTTON}>
            {`I'm at least 21 years old`}
          </Button>
          <StyledAnchor
            data-e2eid={TEST_IDS.GOOGLE_LINK}
            onClick={this.handleDismiss}
            href="https://google.com"
            rel="noopener noreferrer">
            {`I'm not 21 yet`}
          </StyledAnchor>
        </AgeGateModal>
      </AlertDrawer>
    )
  }
}

const AgeGateModal = styled.div`
  align-items: center;
  background: ${white};
  border-radius: ${defaultBorderRadius};
  display: flex;
  flex-direction: column;
  max-height: 95%;
  outline: none;
  overflow: hidden;
  padding: 3rem 2rem 0.5rem;
  position: relative;
  width: 28rem;
  z-index: 11;

  @media (max-width: 767px) {
    width: 26rem;
  }
`

const SectionTitleStyled = styled(SectionTitle)`
  color: ${Colors.primary[1]};
  font-size: 1.4rem;
  letter-spacing: 0.04em;
  margin-top: 1rem;

  @media (max-width: 767px) {
    font-size: 1.2rem;
  }
`

const TextStyled = styled(Text)`
  font-size: 1.2rem;
  line-height: 1.4rem;
  margin: 1rem 0 3rem;
  padding: 0 0.5rem;
  text-align: center;
`

const StyledAnchor = styled.a`
  font-size: 1.2rem;
  font-weight: 400;
  letter-spacing: 0.03em;
  margin-top: 0.5rem;
  padding: 1rem;
  text-transform: uppercase;
`
