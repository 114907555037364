/**
 * Modal that shows up on /menu after a successful password reset / change.
 */

import React, { PureComponent } from 'react'

import Button, { PRIMARY } from '@components/button'

import AlertDrawer from './alert-modal'
import { CloseModalIcon, Emoji, Message, Modal, Paragraph, Title } from './style'

import { func } from 'prop-types'

export default class ChangedPassword extends PureComponent {
  static propTypes = {
    setChangePassword: func
  }

  handleCloseRequest = () => {
    this.props.setChangePassword(false)
  }

  render() {
    return (
      <AlertDrawer open onRequestClose={this.handleCloseRequest}>
        <CloseModalIcon onClick={this.handleCloseRequest} src="/static/icons/close.svg" />
        <Modal>
          <Message>
            <Title>Success!</Title>
            <Emoji>🔑</Emoji>
            <Paragraph>Your password has successfully been changed.</Paragraph>
          </Message>
          <Button buttonType={PRIMARY} onClick={this.handleCloseRequest}>
            Start shopping
          </Button>
        </Modal>
      </AlertDrawer>
    )
  }
}
