import React from 'react'

import Link from 'next/link'

import Button, { PRIMARY } from '@components/button'

import AlertDrawer from './alert-modal'
import { ButtonRow, CloseModalIcon, Emoji, Message, Modal, Paragraph, Title } from './style'

import { func, string } from 'prop-types'

const ShowIDs = ({ alertKey, setAlertTypeVisibility, recUrl }) => {
  const requestClose = () => {
    setAlertTypeVisibility(alertKey, false)
  }

  return (
    <AlertDrawer open onRequestClose={requestClose}>
      <CloseModalIcon onClick={requestClose} src="/static/icons/close.svg" />
      <Modal>
        <Message>
          <Title>Have Your IDs Ready</Title>
          <Emoji>🏛</Emoji>
          <Paragraph>
            Your driver must confirm your state ID and medical recommendation in order to complete your delivery.
          </Paragraph>
        </Message>
        <ButtonRow>
          <Button buttonType={PRIMARY} onClick={requestClose}>
            Okay, got it!
          </Button>

          <Link href={recUrl || ''} rel="noopener noreferrer" target="_blank" style={{ width: '100%' }}>
            <Button buttonType={PRIMARY} inverted>
              View my medical card
            </Button>
          </Link>
        </ButtonRow>
      </Modal>
    </AlertDrawer>
  )
}

export default ShowIDs

ShowIDs.propTypes = {
  alertKey: string,
  recUrl: string,
  setAlertTypeVisibility: func
}
