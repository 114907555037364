/**
 * Upload error modal component used for state ID and MMID upload
 * Shows a loading spinner and a message
 */

import React from 'react'


import Button, { PRIMARY } from '@components/button'

import AlertDrawer from './alert-modal'
import { CloseModalIcon, Emoji, Message, Modal, Paragraph, Title } from './style'

import { func, string } from 'prop-types'

const UploadError = ({ alertKey, setAlertTypeVisibility, verificationProcessErrors }) => {
  const onClick = () => {
    setAlertTypeVisibility(alertKey, false)
  }

  return (
    <AlertDrawer open onRequestClose={onClick}>
      <CloseModalIcon onClick={onClick} src="/static/icons/close.svg" />
      <Modal>
        <Message>
          <Title>Oops!</Title>
          <Emoji>😳</Emoji>
          <Paragraph>{verificationProcessErrors}</Paragraph>
        </Message>

        <Button onClick={onClick} buttonType={PRIMARY}>
          OK
        </Button>
      </Modal>
    </AlertDrawer>
  )
}

UploadError.propTypes = {
  // Does not have any props yet
}

export default UploadError

UploadError.propTypes = {
  alertKey: string,
  setAlertTypeVisibility: func,
  verificationProcessErrors: string
}
